import MainLayout from "../layouts/MainLayout";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

function Faq() {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const questions = [{
    title: "¿Qué es Bidi?", content: <div>
      <p>
        Bidi es la Biblioteca Digital que se integra a todo tipo de institución. Bidi es un sitio web que le permite a los
        usuarios gestionar préstamos y reservas de libros asociados al catálogo de la Institución.
      </p>
    </div>
  },
  {
    title: "¿Qué puedo hacer Bidi?", content: <div>
      <p>
        Una vez logueado podrás navegar el catálogo, realizar búsquedas, solicitar préstamos, descargar los libros para
        leerlos, realizar reservas, cancelar reservas, ordenar el resultado de las búsquedas, refinar el resultado de las
        búsqueda, ver el detalle de los libros, administrar tu perfil.
      </p>
    </div>
  },
  {
    title: "¿Qué es un libro?", content: <div>
      <p>
        Un libro es un libro electrónico, también conocido como ecolibro o libro digital. En otras palabras, es la versión
        electrónica o digital de un libro. Esta creciente tecnología permite al lector disponer de una verdadera librería
        móvil y una gran variedad de títulos y contenidos de descarga, sin moverse de su casa, lugar de trabajo, estudio o
        vacaciones.
      </p>
    </div>
  },
  {
    title: "¿En qué formatos se descargan los libros?", content: <div>
      <p>
        Los libros se descargan en formato ePub o PDF y pueden estar protegidos con DRM de Adobe (el sistema de protección
        de derechos digitales más utilizado en el mundo). DRM son las siglas en inglés de Administración de Derechos
        Digitales, que protege los derechos de autor de los libros que puedes descargar de la Biblioteca Digital.
      </p>
    </div>
  },
  {
    title: "¿Todos los libros están protegidos con DRM de Adobe?", content: <div>
      <p>
        Las editoriales eligen utilizar o no la protección de DRM de Adobe. Para identificar si un libro tiene protección
        debes ingresar al interior de un libro y verás en "Formato", PDF o ePub (Adobe DRM).
      </p>
    </div>
  },
  {
    title: "¿Puedo leer los libros sin estar conectado a internet?", content: <div>
      <p>
        Sí, siempre y cuando se encuentren descargados en tu dispositivo previamente.
      </p>
    </div>
  },
  {
    title: "¿Puedo leer los libros en línea?", content: <div>
      <p>
        Sí, siempre y cuando el libro soporte la modalidad de lectura en línea.
      </p>
    </div>
  },
  {
    title: "¿Qué es mi perfil?", content: <div>
      <p>
        Esta sección contiene las opciones que te permitirán configurar los datos en Mi Perfil y visualizar los libros en
        Mis libros, Mis Reservas y en el Historial. Desde "Mi perfil" podrás cambiar tu contraseña, crear la clave de
        lectura para descargar la App de Bidi. En "Mis libros" visualizarás los libros activos, con la opción para
        Descargarlos. En "Mis reservas" verás los libros reservados, la opción para cancelarlos y la cantidad de personas
        que están delante tuyo. En el "Historial" verás todos los libros que has tomado prestados.
      </p>
    </div>
  },
  {
    title: "¿Qué es Bidi App - Clave de Lectura?", content: <div>
      <p>
        Para leer los libros protegidos con DRM necesitas una aplicación de lectura. Aquí te ofrecemos la App de Bidi que
        podrás descargar de manera gratuita, sólo necesitarás configurar tus datos para crear tu <strong>clave de lectura</strong>
        , y luego utilizar esos datos para autorizarlo los dispositivos.
      </p>
    </div>
  },
  {
    title: "¿Cómo recupero o cambio la contraseña de Bidi App?", content: <div>
      <p>
        Para realizar el cambio debes ingresar a Mi perfil --&gt; Bidi App - Clave de Lectura, colocar la contraseña,
        repetir la misma y presionar Actualizar. All ingresar a la aplicación de Bidi desde el dispositivo o PC, primero
        debes desautorizar el mismo, y luego autorizarlos con el email y la contraseña modificada.
      </p>
      <div className="notas">
        <p>
          <strong>Recuerda:</strong> Una vez modificada la contraseña debes cambiar los datos en tu dispositivo, de lo contrario no vas a poder leer
          el libro en la App o en Adobe Digital Editions.
        </p>
      </div>
    </div>
  },
  {
    title: "¿Puedo cambiar la contraseña de Bidi App desde mis dispositivos móviles?", content: <div>
      <p>
        Sí, puedes cambiarla con la opción "Olvidé mi contraseña App", recibirás un email para cambiar la contraseña.
        Luego debes desautorizar todos los dispositivos y volverlos a autorizar.
      </p>
      <div className="notas">
        <p>
          <strong>Recuerda:</strong> Una vez modificada la contraseña debes cambiar los datos en tu dispositivo, de lo contrario no vas a poder leer
          el libro en la App o en Adobe Digital Editions.
        </p>
      </div>
    </div>
  },
  {
    title: "¿Tengo que usar la App de Bidi para leer los libros?", content: <div>
      <p>
        No, puedes utilizar el reader que ya tengas instalado, para ello debes indicar "Ya tengo reader" en la primer
        descarga de un libro.
      </p>
    </div>
  },
  {
    title: "¿Cuándo debo configurar mi clave de lectura para leer los libros en la App de Bidi?", content: <div>
      <p>
        La primera vez que realices una descarga, te solicitaremos completar la contraseña. En el caso que no lo hayas
        completado, puedes acceder a Mi perfil → Bidi App - Clave de lectura, completar los campos de contraseña y
        Actualizar. Dentro de los dispositivos utiliza el email y contraseña para autorizar los dispositivos móviles. En
        el caso del Adobe, debes seleccionar como proveedor "Bidi", completar mail y contraseña, y luego Autorizar.
      </p>
    </div>
  },
  /*{
    title: "¿Cómo recupero o cambio la contraseña de BajaLibros?", content: <div>
      <p>
        Para realizar el cambio debes ingresar a Mi perfil --&gt; Bajalibros App - Clave de Lectura, la opción "No
        recuerdo mi contraseña de BajaLibros". Te redirigirá al login y allí podrás recuperar o cambiar tu contraseña.
        Luego al ingresar a la aplicación de Bajalibros desde el dispositivo o PC, primero debes desautorizar el mismo, y
        luego autorizarlos con el email y la contraseña modificada.
      </p>
      <div className="notas">
        <p>
          <strong>Recuerda:</strong> Una vez modificada la contraseña debes cambiar los datos en tu dispositivo, de lo contrario no vas a poder leer
          el libro en la App o en Adobe Digital Editions.
        </p>
      </div>
    </div>
  },*/
  {
    title: "¿Cuántos libros puedo pedir en préstamo de manera simultánea?", content: <div>
      <p>
        La cantidad de libros está dispuesta por las reglas de la Institución. Si tienes dudas puedes contactarnos.
      </p>
    </div>
  },
  {
    title: "¿Cuántos libros puedo tener reservados de manera simultánea?", content: <div>
      <p>
        La cantidad de libros está dispuesta por las reglas de la Institución. Si tienes dudas puedes contactarnos.
      </p>
    </div>
  },
  {
    title: "¿Cómo me informan cuando un libro reservado ya está disponible?", content: <div>
      <p>
        Al ingresar por primera vez a Bidi, Aceptas la notificación a tu email de la disponibilidad del libro reservado.
        De esta manera te llegará a la dirección de email provista, la notificación del libro disponible. Revisa en la
        bandeja de spam, si no lo encuentras en la bandeja de entrada.
      </p>
    </div>
  },
  {
    title: "¿Puedo reservar un libro varias veces?", content: <div>
      <p>
        No, sólo puedes tener el libro en reserva una vez, y estará en la sección Mis Reservas.
      </p>
    </div>
  },
  {
    title: "¿Puedo cancelar una reserva?", content: <div>
      <p>
        Sí, debes ingresar a Mis Reservas, y presionar Cancelar Reserva.
      </p>
    </div>
  },
  {
    title: "¿Dónde visualizo mis libros solicitados en préstamos?", content: <div>
      <p>
        Se visualizarán en la sección Mis libros. Estarán ordenados desde el último tomado en préstamo hacia el primero.
        Los libros que tengan la opción Descargar, son los que aún puedes leer en tu dispositivo.
      </p>
    </div>
  },
  {
    title: "¿Dónde visualizo mis libros reservados?", content: <div>
      <p>
        Se visualizarán en la sección Mis Reservas. Estarán ordenados desde el último reservado hacia el primero. Los
        libros que visualices en el listado, se podrán cancelar.
      </p>
    </div>
  },
  {
    title: "¿Qué se registra en Historial?", content: <div>
      <p>
        En esta sección se visualizarán todos los libros tomados en préstamos cuyo período de préstamo ha expirado o hayas
        devuelto anticipadamente.
      </p>
    </div>
  },
  {
    title: "¿Cómo eliminar los datos de mi cuenta?", content: <div>
      <p>
        Para comenzar este proceso deberás enviar un email a info@ticmas.com.
      </p>
    </div>
  },];

  if (!!institution.salesAllowed) {
    questions.push({
      title: "¿Puedo comprar un libro?", content: <div>
        <p>
          Sí, puedes acceder desde la opción Comprar libros, o a través de la opción Comprar. Cualquiera de estas maneras te
          redirigirá a BajaLibros. Debes tener en cuenta, que deberás registrarte en BajaLibros para poder realizar la
          compra.
        </p>
      </div>
    });
  }

  questions.push({
    title: "En caso de tener un problema ¿Dónde puedo contactarme?", content: <div>
      <p>
        Por favor revisa las secciones Ayuda y Preguntas Frecuentes, donde puedes corroborar que no se encuentre allí la
        solución.
      </p>
      <p>
        Si el error persiste puedes contactarte desde Argentina al {institution.helpContactPhone1}
        {institution.helpContactPhone2 && <span>
          , {institution.helpContactPhone2} desde el exterior
        </span>}, o por email a {institution.helpContactEmail}
      </p>
      <p>
        Siempre indica tu email, institución a la que perteneces, dispositivo utilizado, y si el error te ha dado algún
        mensaje por favor colocarlo textualmente o una captura de pantalla.
      </p>
    </div>
  });

  return (
    <MainLayout currentPageName="Preguntas frecuentes">
      <div className="py-4">
        <div className="flex items-center">
          <div className="flex-grow">
            <h2 className="text-2xl font-bold">Ayuda</h2>
          </div>
        </div>
        <div className="py-4">
          {questions.map((q, index) =>
            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              ><HelpIcon className="mr-2 text-[#258BD1]" /><Typography className="font-bold">{q.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {q.content}
              </AccordionDetails>
            </Accordion>
          )}

        </div>
      </div>
    </MainLayout>
  );
}

export default Faq;