import MainLayout from "../layouts/MainLayout";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import SimpleModal from "../components/modals/SimpleModal";
import PasswordField from "../components/PasswordField";
import ReadingKey from "../components/ReadingKey";
import { AuthContext } from "../contexts/AuthContext";
import { customText } from "../customization/customText";
import { updateUserProfile } from "../services/bidiService";

function Profile() {
  const authContext = useContext(AuthContext);

  const user = authContext.authData.user;
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangePasswordSubmit = (event) => {
    event.preventDefault();

    if (event.target.password.value !== event.target.repeat_password.value) {
      setError("Las contraseñas no coinciden");
      setLoading(false);
      return;
    }

    updateUserProfile(authContext.authData.bidi_access_token, {
      password: event.target.password.value,
    })
      .then((response) => {
        event.target.password.value = "";
        event.target.repeat_password.value = "";
        setExpanded("");
        setModalMessage("La contraseña se cambió con éxito.");
        setError("");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <MainLayout
      loading={loading}
      currentPageKey="Configuración"
      currentPageName={customText("breadcrumbs.profile")}
    >
      <SimpleModal
        show={modalMessage}
        onClose={() => {
          setModalMessage("");
        }}
        title={modalMessage}
        description=""
      ></SimpleModal>
      <div className="py-8">
        <div>
          <div className="flex items-center" id="profile-head">
            <div className="flex-grow">
              <h2 className="text-2xl font-bold">Mis datos personales</h2>
            </div>
          </div>
          <div className="py-4">
            <div className="flex flex-wrap gap-20">
              <div className="flex flex-1 flex-col gap-8" id="profile-data">
                <TextField
                  fullWidth
                  id="name"
                  label="Nombre"
                  name="name"
                  defaultValue={user.name}
                  disabled
                  variant="standard"
                />
                <TextField
                  fullWidth
                  id="lastname"
                  label="Apellido"
                  name="lastname"
                  defaultValue={user.lastName}
                  disabled
                  variant="standard"
                />
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  defaultValue={user.email}
                  disabled
                  variant="standard"
                />
                <TextField
                  fullWidth
                  id="external_id"
                  label="Usuario"
                  name="external_id"
                  defaultValue={user.external_id}
                  disabled
                  variant="standard"
                />

                {authContext.authData.access_method === "native" && (
                  <Accordion
                    expanded={expanded === `panel2`}
                    onChange={handleChange(`panel2`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="formulario cambio de contraseña"
                    >
                      <Typography className="font-bold">
                        Modificar contraseña
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack
                        spacing={3}
                        component="form"
                        onSubmit={handleChangePasswordSubmit}
                      >
                        <PasswordField
                          id="password"
                          label="Nueva contraseña"
                          name="password"
                          onClickShowPassword={(show) => setShowPassword(show)}
                          required
                        />
                        <TextField
                          id="repeat_password"
                          error={!!error}
                          helperText={error}
                          label="Repetir contraseña"
                          name="repeat_password"
                          required
                          type={showPassword ? "text" : "password"}
                          variant="standard"
                        />

                        <div className="text-center">
                          <Button variant="contained" type="submit">
                            Enviar
                          </Button>
                        </div>
                      </Stack>
                      <div className="mt-6 flex">
                        <HelpIcon className="mr-2 text-[#258BD1]" />
                        <div>
                          Esta contraseña es la que permite ingresar a la web.
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
              <div id="profile-reading-key" className="flex-1">
                <Card>
                  <CardContent>
                    <ReadingKey
                      helperText={customText("profile.reading_key_text2")}
                      onSuccess={(message) => setModalMessage(message)}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Profile;
