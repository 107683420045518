import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import AcceptConditionsModal from "./modals/AcceptConditionsModal";
import DataValidationModal from "./modals/DataValidationModal";
import TutorialModal from "./modals/TutorialModal";
import WelcomeVideoModal from "./modals/WelcomeVideoModal";

const CommandsHandler = () => {
  const authContext = useContext(AuthContext);

  const [commands, setCommands] = useState(authContext.authData.commands);
  const [currentCommand, setCurrentCommand] = useState();

  useEffect(() => {
    if(commands) {
      setCurrentCommand(commands[0]);
    }
  }, [commands]);

  const onClose = (redirect_url) => {
    const [prevCommand, ...nextCommands] = commands;
    setCommands(nextCommands);
    authContext.updateCommands(nextCommands);

    if (redirect_url) {
      window.location = redirect_url;
    }
  }

  return <>
    {currentCommand?.name === 'ShowWelcome' && <WelcomeVideoModal onClose={onClose} />}
    {currentCommand?.name === 'ShowTutorial' && <TutorialModal onClose={onClose} />}
    {currentCommand?.name === 'AcceptsConditions' && <AcceptConditionsModal onClose={onClose} />}
    {currentCommand?.name === 'DataValidation' && <DataValidationModal onClose={onClose} />}
  </>;
}

export default CommandsHandler;