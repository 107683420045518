import DownloadApps from '../components/DownloadApps';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import Footer from '../components/Footer';
import SearchBar from '../components/SearchBar';
import Menu from '../components/Menu';
import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import TagManager from 'react-gtm-module';
import CommandsHandler from '../components/CommandsHandler';
import Topbar from '../components/Topbar';
import Breadcrumb from '../components/Breadcrumbs';
import Navbar from '../components/Navbar';


const MainLayout = ({ loading,currentPageKey, currentPageName, children }) => {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;
  const user = authContext.authData.user;

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
      let data = {};

      if (institution) {
        data.institution = institution.name;
      }

      if (user) {
        data.userId = user.id;
      }
      TagManager.dataLayer(data);
    }

    if (institution && institution.customCss) {
      var sheet = document.createElement('style');
      sheet.innerHTML = institution.customCss;
      document.head.appendChild(sheet);
    }
  }, []);

  useEffect(() => {
    if (institution && institution.customJs) {
      eval(`try { ${institution.customJs} } catch(e) {}`);
    }
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: authContext.authData.institution.primaryColor,
      },
      secondary: {
        main: authContext.authData.institution.secondaryColor
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CommandsHandler />
      <div className="flex flex-col w-full min-h-screen">
        <Topbar />
        <header className="bg-[#f7f9f9] p-5 flex justify-center relative">
          <div className="flex flex-grow flex-wrap sm:flex-nowrap flex-col-reverse sm:flex-row justify-center items-center max-w-5xl">

            <div className="flex flex-grow w-full">
              <div id="menu" className="self-center pr-6 absolute top-[2rem] sm:relative sm:top-0">
                <Menu />
              </div>
              <div className="flex-grow">
                <SearchBar />
              </div>
            </div>
            <div id="logo" className="mx-4 mb-6 sm:mb-0">
              <h1>
                <a href="/">
                  <img className="h-14 max-w-none" alt="Logo de la biblioteca" src={`${process.env.REACT_APP_IMAGES_BASE_URL}/${authContext.authData.institution.logo}`} />
                </a>
              </h1>
            </div>
          </div>

        </header>

        <div id="breadcrumb" className="bg-[#f7f9f9]   flex justify-center relative ">
          <div className="flex flex-grow flex-wrap sm:flex-nowrap px-6  pt-2 items-center  mx-auto mb-auto w-full bg-[#f7f9f9]  max-w-5xl ">
            {!loading && institution.showBreadcrumbs &&
              <div className="py-2  text-xs justify-center">
                <Breadcrumb currentPageName={currentPageName} />
              </div>}
          </div>
        </div>

        {!loading && institution.showNavigationBar && <Navbar currentPageKey={currentPageKey} currentPageName={currentPageName} />}

        <section className="block mx-auto mb-auto w-full px-6 max-w-5xl">
          {loading ?
            <div className="flex justify-center  items-center h-full py-8"><CircularProgress /></div> : children}
        </section>
        {institution.showDownloadApps && <div className="flex justify-center">
          <div className="flex-grow w-full max-w-5xl">
            <DownloadApps />
          </div>
        </div>}
        <Footer />
      </div>
    </ThemeProvider>);
}

export default MainLayout;