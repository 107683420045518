import AndroidAppImage from './../assets/images/android-app.png';
import iOsAppImage from './../assets/images/ios-app.png';

const DownloadApps = ({ loading, ...props }) => {
  return (
    <div id="downloadApps" className="flex flex-grow justify-between bg-[#282828]">
      <div className="px-6 py-4 text-white">
        <h3 className="text-3xl font-semibold">Descarga nuestra app.</h3>
        Encuéntrala en todas las plataformas.
      </div>
      <div className="flex flex-wrap items-center justify-center bg-black">
        <a title="Descargá nuestra aplicación para Android" href="https://play.google.com/store/apps/details?id=com.grupovida.bidi">
          <img src={AndroidAppImage} alt="Descargá nuestra aplicación para Android" className="h-12" title="Dispositivos" />
        </a>
        <a title="Descargá nuestra aplicación para iOS" href="https://itunes.apple.com/ar/app/bidi-reader/id1406896115">
          <img src={iOsAppImage} alt="Descargá nuestra aplicación para iOS" className="h-12" title="Dispositivos" />
        </a>
      </div>
    </div>
  );
}

export default DownloadApps;