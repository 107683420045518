import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import BidiLogo from './../assets/images/bidi169x57.png';

const Footer = () => {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;
  const user = authContext.authData.user;

  return (<footer className="bg-[#4c4c4c] text-white py-14">
    <div className="flex justify-between gap-12 flex-wrap max-w-4xl m-auto px-6">
      <div>
        <h4 className="pb-6 font-bold uppercase">Biblioteca Digital</h4>
        <ul>
          <li><span></span></li>
          <li>
            <a title="Ir a ayuda" href="/ayuda"><span>Ayuda</span></a>
          </li>
          <li>
            <a title="Ir a preguntas frecuentes" href="/preguntasfrecuentes"><span>Preguntas Frecuentes</span></a>
          </li>
          <li>
            <a title="Ir a terminos y condiciones" href="/terminosycondiciones"><span>Términos y Condiciones</span></a>
          </li>
          <li>
            <a title="Ir a tutorial" className="tutorial" href="/tutorial"><span>Tutorial</span></a>
          </li>
          {!!institution.salesAllowed && <li>
            <a target="_blank" rel="noreferrer" title="Ir a comprar Libros" href={`http://www.bajalibros.com/?affiliatescode=${institution.affiliatesCode}`}><span>Comprar libros</span></a>
          </li>}
          <li>
            <a title="Descargar Adobe Digital Editions" target="_blank" href="https://www.adobe.com/ar/solutions/ebook/digital-editions/download.html"><span>Descargar Adobe Digital Editions</span></a>
          </li>
        </ul>
      </div>
      {user && <div>
        <h4 className="pb-6 font-bold uppercase">Mi cuenta</h4>
        <ul>
          <li>
            <a title="Ir a mi perfil" href="/perfil"><span>Mi perfil</span></a>
          </li>
          <li>
            <a title="Ir a mis Libros" href="/mislibros"><span>Mis libros</span></a>
          </li>
          {!!institution.loansAllowed && <li>
            <a title="Ir a mis reservas" href="/misreservas"><span>Mis reservas</span></a>
          </li>}
          <li>
            <a title="Ir a mis favoritos" href="/misfavoritos"><span>Mis Favoritos</span></a>
          </li>
          <li>
            <a title="Ir a historial" href="/historial"><span>Historial</span></a>
          </li>
        </ul>
      </div>}
      <div>
        <h4 className="pb-6 font-bold uppercase">HABLA CON NOSOTROS</h4>
        <ul>
          <li>
            <a title={`Enviar correo a ${institution.helpContactEmail}`} href={`mailto:${institution.helpContactEmail}`}><EmailIcon /> {institution.helpContactEmail}</a>
          </li>
          {institution.helpContactPhone1 && <li>
            <span title="Teléfono de contacto primario"><PhoneIcon /> {institution.helpContactPhone1}</span>
          </li>}
          {institution.helpContactPhone2 && <li>
            <span title="Teléfono de contacto secundario"><PhoneIcon /> {institution.helpContactPhone2}</span>
          </li>}
          {institution.helpContactWhatsapp && <li>
            <span title="Teléfono de contacto de whatsapp"><WhatsappIcon /> {institution.helpContactWhatsapp}</span>
          </li>}
          {institution.facebookDescription && <li>
            <a target="_blank" rel="noreferrer" href={institution.facebookLink} title={institution.facebookDescription}>
              <FacebookIcon /> {institution.facebookDescription}
            </a>
          </li>}
          <li>
            <a title="Ir a inicio" href={institution.logoFooterLink || '/'}>
              <img alt="Logo de la biblioteca" className="pt-6 h-[5.5rem]" src={(institution.logoFooterName) ? `${process.env.REACT_APP_IMAGES_BASE_URL}/${institution.logoFooterName}` : BidiLogo} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>);
}

export default Footer;