import { CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { updateUserProfile } from "../../services/bidiService";
import SimpleModal from "./SimpleModal";

const WelcomeVideoModal = ({ onClose, ...props }) => {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;

  const [loading, setLoading] = useState(false);

  const video = institution.welcomeVideoUrl || "https://www.youtube.com/embed/hRaKSk76nck?autoplay=1&modestbranding=1&rel=0";

  const handleOnClose = (event) => {
    event.preventDefault();
    setLoading(true);
    updateUserProfile(authContext.authData.bidi_access_token, { "show_welcome": false }).then(() => {
      onClose();
    }).catch(() => setLoading(false));
  };

  return <SimpleModal onClose={handleOnClose} {...props} show={true}>
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <iframe className="w-full h-80" src={video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
  </SimpleModal>
};

export default WelcomeVideoModal;