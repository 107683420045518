import MainLayout from "../layouts/MainLayout";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

import MiPerfilImage from "../assets/images/help/mi_perfil.png";
import MiPerfilCuentaImage from "../assets/images/help/mi_perfil_cuenta.png";
import MiPerfilClaveLecturaImage from "../assets/images/help/mi_perfil_clave_de_lectura.png";
import VerCatalogoImage from "../assets/images/help/ver_catalogo.png";
import CatalogoMiniaturaImage from "../assets/images/help/catalogo_miniatura.png";
import CatalogoListaImage from "../assets/images/help/catalogo_lista.png";
import CatalogoOrdenarImage from "../assets/images/help/catalogo_ordenar.png";
import CrearClaveLecturaImage from "../assets/images/help/crear_clave_lectura.png";
import CrearClaveLectura2Image from "../assets/images/help/crear_clave_lectura_2.png";
import MiPerfilBidiAppImage from "../assets/images/help/mi_perfil_bidi_app.png";
import AdeImage from "../assets/images/help/ade.png";
import DispositivosImage from "../assets/images/help/dispositivos.png";
import EbookStreamingImage from "../assets/images/help/ebook_streaming.png";
import StreamingImage from "../assets/images/help/streaming.png";
import AdobeDevolver1Image from "../assets/images/help/adobe_devolver_1.png";
import AdobeDevolver2Image from "../assets/images/help/adobe_devolver_2.png";
import AdobeDevolver4Image from "../assets/images/help/adobe_devolver_4.png";
import HistorialImage from "../assets/images/help/historial.png";
import FiltrarImage from "../assets/images/help/filtrar.png";
import DevApp1Image from "../assets/images/help/dev_app.jpg";
import DevApp2Image from "../assets/images/help/dev_app2.jpg";
import DevApp3Image from "../assets/images/help/dev_app3.jpg";
import BuscadorImage from "../assets/images/help/buscador.png";
import Tags2Image from "../assets/images/help/tags_2.png";
import Tags9Image from "../assets/images/help/9.tags.PNG";
import Buscador7Image from "../assets/images/help/7.buscador.PNG";


function Help() {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const questions = [{
    title: "¿Cómo accedo a Mi Perfil?", content: <div>
      <p>
        Encontrarás el menú en la parte superior derecha, al presionar sobre el nombre, se desplegará el
        menú:
      </p>
      <br />
      <figure>
        <img src={MiPerfilImage} alt="" className="w-100" height="141" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        Allí podrás optar por seleccionar Mi perfil, Mis libros, Mis reservas o Historial, e ingresar a la
        opción seleccionada:
      </p>
      <br />
      <figure>
        <img src={MiPerfilCuentaImage} alt="" className="w-100" height="233" title="Ayuda" width="500" />
      </figure>
      <br />
      <div className="notas">
        <p>
          <strong>
            Nota:
          </strong>
          Recuerda que para leer los libros protegidos con DRM, necesitas autorizar tu PC/Mac o
          dispositivo que utilices. Descargá la App de Bidi, creando tu clave de lectura desde la opción
          Mi perfil --&gt; Bidi App - Clave de Lectura.
        </p>
      </div>
      <br />
      <figure>
        <img src={MiPerfilClaveLecturaImage} alt="" className="w-100" height="233" title="Ayuda" width="500" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Dónde se localizan los libros?", content: <div>
      <p>
        En la home verás la opción "Ver Catálogo", o también, desplegando el menú principal--&gt; catálogo:
      </p>
      <br />
      <figure>
        <img src={VerCatalogoImage} alt="" className="w-100" height="131" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        Al presionar el botón ingresarás al catálogo de libros, el cual podrás ver en vista mosaico o lista:
      </p>
      <br />
      <figure>
        <img src={CatalogoMiniaturaImage} alt="" className="w-100" height="178" title="Ayuda" width="280" />
      </figure>
      <br />
      <br />
      <figure>
        <img src={CatalogoListaImage} alt="" className="w-100" height="174" title="Ayuda" width="280" />
      </figure>
      <br />
      <p>
        Además, podrás ordenar el resultado por alguno de los siguientes campos:
      </p>
      <br />
      <figure>
        <img src={CatalogoOrdenarImage} alt="" className="w-100" height="174" title="Ayuda" width="280" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Cómo leo mis libros?", content: <div>
      <div className="notas">
        <p>
          <strong>
            Recuerda:
          </strong>
          <i>
            <span>
              Debes tener en cuenta que para leer los libros protegidos con DRM, siempre necesitas
              <u>
                <i>
                  autorizar
                </i>
              </u>
              tu PC/Mac o dispositivo que utilices con tu clave de lectura.
            </span>
          </i>
        </p>
      </div>
      <p>
        La primera vez que descargues un libro, un asistente de configuración te guiará para crear tu clave
        de lectura y descargar la App.
      </p>
      <br />
      <figure>
        <img src={CrearClaveLecturaImage} alt="" className="snip-img" title="Ayuda" width="350" />
      </figure>
      <br />
      <p>
        Al presionar Continuar, debes seleccionar el dispositivo que vayas a utilizar para descargar la
        aplicación. Sigue las instrucciones de descarga de acuerdo al dispositivo seleccionado.
      </p>
      <br />
      <figure>
        <img src={CrearClaveLectura2Image} alt="" height="369" title="Ayuda" width="349" className="snip-img" />
      </figure>
      <br />
      <p>
        Accede a estos links para comenzar la descarga:
        <br />
        <span>
          • Para PC/NoteBook con Windows o Mac, debes instalar por única vez el programa gratuito
          <a target="_blank" rel="noreferrer" href="https://www.adobe.com/la/solutions/ebook/digital-editions/download.html">
            Adobe Digital Editions
          </a>
          .
        </span>
        <br />
        <span>
          • Para iPad &amp; iPhone (9.1 en adelante), debes descargar la aplicación exclusiva Bidi Reader
          del
          <a target="_blank" rel="noreferrer" href="https://itunes.apple.com/ar/app/bidi-reader/id1406896115">
            App Store
          </a>
          .
        </span>
        <br />
        <span>
          • Para tablets y smartphones con Android (4.0.3 en adelante), debes descargar la aplicación
          exclusiva Bidi Reader de
          <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.grupovida.bidi">
            Google Play
          </a>
          .
        </span>
      </p>
      <p>
        Descargada la App, debes autorizar cada dispositivo que vayas a utilizar, con la clave de lectura
        (email - contraseña), ingresados anteriormente. Esta información la puedes visualizar dentro de
        <b>
          Mi Perfil, Bidi App - Clave de Lectura
        </b>
        , donde podrás Actualizar tu contraseña, en el caso que no la recuerdes.
      </p>
      <br />
      <figure>
        <img src={MiPerfilBidiAppImage} alt="" className="w-100" height="131" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        Finalmente, ingresa a Mis libros, presiona
        <b>
          "Descargar"
        </b>
        sobre el libro tomado en préstamo y se abrirá la aplicación de lectura. La primera vez que lo
        realices, deberás seleccionar como Proveedor
        <b>
          "Bidi"
        </b>
        , e ingresar el usuario y contraseña informados en el punto anterior (email y contraseña).
        <i>
          Esto lo harás por única vez.
        </i>
      </p>
      <br />
      <figure>
        <img src={AdeImage} alt="" className="w-100 max-w-2xl" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        Los libros los podrás leer hasta en 6 dispositivos usando tu clave de lectura. Una vez descargados
        los libros, podrás leerlos sin estar conectado a Internet.
      </p>
    </div>
  },
  {
    title: "¿Cómo descargo la App de Bidi?", content: <div>
      <p>
        La primera vez que hagas una descarga de un libro, un asistente te guiará paso a paso para
        configurar tu clave de lectura.
      </p>
      <p>
        También, podrás actualizar la contraseña desde
        <b>
          Mi Perfil, Bidi App - Clave de lectura,
        </b>
        y completar la contraseña, en el caso que no la recuerdes:
      </p>
      <br />
      <figure>
        <img src={MiPerfilBidiAppImage} alt="" className="w-100" height="131" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        Descarga la aplicación, por única vez, en el dispositivo que vayas a utilizar desde los links que se
        encuentran en la web:
      </p>
      <br />
      <figure>
        <img src={DispositivosImage} alt="" className="w-100" height="131" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        ó, desde estos links:
        <br />
      </p>
      <p>
        I. Android:
        <a className="link snip-a" href="https://play.google.com/store/apps/details?id=com.grupovida.bidi" lang="en-EN" target="_blank" rel="noreferrer" title="Ir a Google Play">
          Google Play
        </a>
        <br />
        <strong>
          II. iPad o iPhone:
        </strong>
        <a className="link snip-a" href="https://itunes.apple.com/ar/app/bidi-reader/id1406896115" lang="en" target="_blank" rel="noreferrer" title="Ir a App Store">
          App Store
        </a>
        <br />
        <strong>
          III. Pc o Mac:
        </strong>
        <a className="link snip-a" href="https://www.adobe.com/la/solutions/ebook/digital-editions/download.html" lang="en" target="_blank" rel="noreferrer" title="Ir a Adobe Digital Editions">
          Adobe Digital Editions
        </a>
        <br />
        <strong>
          * IV. eReaders:
        </strong>
        <span>
          descargar el Adobe Digital Editions en PC o Mac y luego conectar el eReaders a la PC para enviar
          los libros ya descargados.
        </span>
      </p>
      <p>
        La aplicación descargada en el dispositivo te solicitará
        <b>
          proveedor, usuario y contraseña
        </b>
        para autorizar el dispositivo, aquí debes ingresar email y contraseña informados en la sección
        anterior.
      </p>
      <br />
      <figure>
        <img src={AdeImage} alt="" className="w-100 max-w-2xl" title="Ayuda" width="500" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Tengo que descargar la App de Bidi para leer los libros?", content: <div>
      <p>
        Si ya tienes una cuenta en otro reader no necesitas descargarte esta App. Cuando realices la primera
        descarga, en el asistente de configuración selecciona la opción
        <i>
          "Ya tengo reader"
        </i>
        .
      </p>
      <br />
      <figure>
        <img src={CrearClaveLecturaImage} alt="" className="snip-img" title="Ayuda" width="350" />
      </figure>
      <br />
      <p>
        Cuando quieras, podrás configurar tu cuenta para descargarte la App de Bidi, desde
        <b>
          Mi perfil → Bidi App - Clave de Lectura
        </b>
      </p>
      <br />
      <figure>
        <img src={MiPerfilBidiAppImage} alt="" className="w-100" height="131" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        Además, si ya tienes la App de Bajalibros, podrás seguir usándola para leer tus libros.
      </p>
    </div>
  },
  {
    title: "¿Cómo leo un libro en línea?", content: <div>
      <p>
        Si el libro que quieres tomar en préstamo presenta el ícono Leer, al presionar sobre el mismo, se
        abrirá el reader y podrás disfrutar de la lectura en línea.
      </p>
      <br />
      <figure>
        <img src={EbookStreamingImage} alt="" className="snip-img" title="Ayuda" width="300" />
      </figure>
      <br />
      <br />
      <figure>
        <img src={StreamingImage} alt="" className="w-100" height="131" title="Ayuda" width="500" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Cómo devuelvo un libro desde mi PC?", content: <div>
      <p>
        Si has terminado de leer el libro y lo quieres devolver anticipadamente, lo puedes hacer. Para ello
        debes ingresar al Adobe Digital Editions (ADE), visualizarás la siguiente pantalla:
      </p>
      <br />
      <figure>
        <img src={AdobeDevolver1Image} alt="" className="w-100" height="196" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        Selecciona el libro a devolver, presiona el botón derecho del mouse y selecciona la opción
        <b>
          Devolver elemento prestado
        </b>
        .
      </p>
      <br />
      <figure>
        <img src={AdobeDevolver2Image} alt="" className="w-100" height="267" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        Al presionar
        <b>
          Devolver
        </b>
        , el libro se quitará de tu Biblioteca del ADE.
      </p>
      <br />
      <figure>
        <img src={AdobeDevolver4Image} alt="" height="237" title="Ayuda" width="506" className="snip-img" />
      </figure>
      <br />
      <div className="notas">
        <p>
          <strong>
            Nota:
          </strong>
          <span>
            Recuerda que esta acción la puedes realizar desde la
            <i>
              Vista Elementos en miniaturas
            </i>
            o
            <i>
              Vista Elementos en forma de lista
            </i>
            .
          </span>
        </p>
      </div>
      <p>
        Este libro lo visualizarás en la sección Historial:
      </p>
      <br />
      <figure>
        <img src={HistorialImage} alt="" className="w-100" height="136" title="Ayuda" width="500" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Cómo devuelvo un libro desde mi dispositivo móvil?", content: <div>
      <p>
        Si has terminado de leer el libro y lo quieres devolver anticipadamente, lo puedes hacer. Para ello
        debes ingresar a la App y dirigirte a Mis Libros:
      </p>
      <br />
      <figure>
        <img src={DevApp1Image} alt="" title="Ayuda" width="350" className="snip-img" />
      </figure>
      <br />
      <p>
        Presiona los puntos y luego la opción Devolver:
      </p>
      <br />
      <figure>
        <img src={DevApp2Image} alt="" title="Ayuda" width="350" className="snip-img" />
      </figure>
      <br />
      <p>
        Selecciona la opción de devolución:
      </p>
      <br />
      <figure>
        <img src={DevApp3Image} alt="" title="Ayuda" width="350" className="snip-img" />
      </figure>
      <br />
      <p>
        Este libro figurará como Expirado en la App, y lo encontrarás en la Web, en la sección Historial:
      </p>
      <br />
      <figure>
        <img src={HistorialImage} alt="" className="w-100" height="136" title="Ayuda" width="500" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Cómo busco un libro?", content: <div>
      <p>
        El buscador se encuentra visible en todo el sitio de la Biblioteca Digital. Para realizar una
        búsqueda, debes posicionarte en el recuadro e introducir el texto a buscar (título, autor,
        editorial, categoría), presiona enter, o click en la lupa.
      </p>
      <br />
      <figure>
        <img src={BuscadorImage} alt="" title="Ayuda" width="600" className="snip-img" />
      </figure>
      <br />
      <p>
        Podrás ver el resultado de tu búsqueda en vista Mosaico o lista, y también ordenar el resultado
        obtenido.
      </p>
      <p>
        Además, podrás refinar el resultado obtenido a través de los filtros:
      </p>
      <br />
      <figure>
        <img src={FiltrarImage} alt="" className="w-100" height="124" title="Ayuda" width="280" />
      </figure>
      <br />
      <p>
        Para quitar todos los filtros, debes presionar "Borrar todo"
      </p>
    </div>
  },
  {
    title: "¿Cómo usar los TAGs?", content: <div>
      <p>
        Los tags son etiquetas asociadas a los libros que te permitirán encontrar de manera ágil, lo que
        estás buscando. Los tags se visualizan en la Home, en la parte inferior:
      </p>
      <br />
      <figure>
        <img src={Tags2Image} alt="" className="w-100" height="130" title="Ayuda" width="280" />
      </figure>
      <br />
      <p>
        Al presionar sobre el tag de interés visualizarás el resultado asociado:
      </p>
      <br />
      <figure>
        <img src={Tags9Image} alt="" className="w-100" height="111" title="Ayuda" width="280" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Cómo puedo refinar el resultado de búsqueda?", content: <div>
      <p>
        Luego de realizar una búsqueda, o presionando sobre un tag, en el resultado de búsqueda verás esta
        opción:
      </p>
      <br />
      <figure>
        <img src={Buscador7Image} alt="" className="w-100" height="130" title="Ayuda" width="280" />
      </figure>
      <br />
      <p>
        Al presionar sobre el filtro, se despliegan los campos por los que se pueden aplicar filtros, y de
        esa manera refinar el resultado obtenido:
      </p>
      <br />
      <figure>
        <img src={FiltrarImage} alt="" className="w-100" height="111" title="Ayuda" width="280" />
      </figure>
      <br />
    </div>
  },
  {
    title: "En caso de tener un problema ¿Dónde puedo contactarme?", content: <div>
      <p>
        Por favor revisa las secciones Ayuda y Preguntas Frecuentes, donde puedes corroborar que no se
        encuentre allí la solución.
      </p>
      <p>
        Si el error persiste puedes contactarte desde Argentina al {institution.helpContactPhone1}
        {institution.helpContactPhone2 && <span>
          , {institution.helpContactPhone2} desde el exterior
        </span>}, o por email a {institution.helpContactEmail}
      </p>
      <p>
        Siempre indica tu email, institución a la que perteneces, dispositivo utilizado, y si el error te ha
        dado algún mensaje por favor colocarlo textualmente o una captura de pantalla.
      </p>
    </div>
  }];



  return (
    <MainLayout currentPageKey="Ayuda" currentPageName="Ayuda" >
      <div className="py-4">
        <div className="flex items-center">
          <div className="flex-grow">
            <h2 className="text-2xl font-bold">Ayuda</h2>
          </div>
        </div>
        <div className="py-4">
          {questions.map((q, index) =>
            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              ><HelpIcon className="mr-2 text-[#258BD1]" /><Typography className="font-bold">{q.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {q.content}
              </AccordionDetails>
            </Accordion>
          )}

        </div>
      </div>
    </MainLayout>
  );
}

export default Help;